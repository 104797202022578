import logo0 from "../assets/images/carrier-logos-new/0.jpg";
import logo102 from "../assets/images/carrier-logos-new/102.jpg";
import logo113 from "../assets/images/carrier-logos-new/113.jpg";
import logo13 from "../assets/images/carrier-logos-new/13.jpg";
import logo131 from "../assets/images/carrier-logos-new/131.jpg";
import logo148 from "../assets/images/carrier-logos-new/148.jpg";
import logo160 from "../assets/images/carrier-logos-new/160.jpg";
import logo168 from "../assets/images/carrier-logos-new/168.jpg";
import logo17 from "../assets/images/carrier-logos-new/17.jpg";
import logo18 from "../assets/images/carrier-logos-new/18.jpg";
import logo199 from "../assets/images/carrier-logos-new/199.jpg";
import logo201 from "../assets/images/carrier-logos-new/201.jpg";
import logo203 from "../assets/images/carrier-logos-new/203.jpg";
import logo228 from "../assets/images/carrier-logos-new/228.jpg";
import logo25 from "../assets/images/carrier-logos-new/25.jpg";
import logo303 from "../assets/images/carrier-logos-new/303.jpg";
import logo304 from "../assets/images/carrier-logos-new/304.jpg";
import logo305 from "../assets/images/carrier-logos-new/305.jpg";
import logo331 from "../assets/images/carrier-logos-new/331.jpg";
import logo334 from "../assets/images/carrier-logos-new/334.jpg";
import logo343 from "../assets/images/carrier-logos-new/343.jpg";
import logo344 from "../assets/images/carrier-logos-new/344.jpg";
import logo35 from "../assets/images/carrier-logos-new/35.jpg";
import logo361 from "../assets/images/carrier-logos-new/361.jpg";
import logo363 from "../assets/images/carrier-logos-new/363.jpg";
import logo366 from "../assets/images/carrier-logos-new/366.jpg";
import logo371 from "../assets/images/carrier-logos-new/371.jpg";
import logo373 from "../assets/images/carrier-logos-new/373.jpg";
import logo377 from "../assets/images/carrier-logos-new/377.jpg";
import logo379 from "../assets/images/carrier-logos-new/379.jpg";
import logo384 from "../assets/images/carrier-logos-new/384.jpg";
import logo388 from "../assets/images/carrier-logos-new/388.jpg";
import logo39 from "../assets/images/carrier-logos-new/39.jpg";
import logo391 from "../assets/images/carrier-logos-new/391.jpg";
import logo404 from "../assets/images/carrier-logos-new/404.jpg";
import logo406 from "../assets/images/carrier-logos-new/406.jpg";
import logo416 from "../assets/images/carrier-logos-new/416.jpg";
import logo418 from "../assets/images/carrier-logos-new/418.jpg";
import logo421 from "../assets/images/carrier-logos-new/421.jpg";
import logo422 from "../assets/images/carrier-logos-new/422.jpg";
import logo425 from "../assets/images/carrier-logos-new/425.jpg";
import logo430 from "../assets/images/carrier-logos-new/430.jpg";
import logo436 from "../assets/images/carrier-logos-new/436.jpg";
import logo438 from "../assets/images/carrier-logos-new/438.jpg";
import logo445 from "../assets/images/carrier-logos-new/445.jpg";
import logo447 from "../assets/images/carrier-logos-new/447.jpg";
import logo467 from "../assets/images/carrier-logos-new/467.jpg";
import logo470 from "../assets/images/carrier-logos-new/470.jpg";
import logo473 from "../assets/images/carrier-logos-new/473.jpg";
import logo479 from "../assets/images/carrier-logos-new/479.jpg";
import logo489 from "../assets/images/carrier-logos-new/489.jpg";
import logo494 from "../assets/images/carrier-logos-new/494.jpg";
import logo498 from "../assets/images/carrier-logos-new/498.jpg";
import logo501 from "../assets/images/carrier-logos-new/501.jpg";
import logo502 from "../assets/images/carrier-logos-new/502.jpg";
import logo503 from "../assets/images/carrier-logos-new/503.jpg";
import logo504 from "../assets/images/carrier-logos-new/504.jpg";
import logo505 from "../assets/images/carrier-logos-new/505.jpg";
import logo506 from "../assets/images/carrier-logos-new/506.jpg";
import logo507 from "../assets/images/carrier-logos-new/507.jpg";
import logo509 from "../assets/images/carrier-logos-new/509.jpg";
import logo510 from "../assets/images/carrier-logos-new/510.jpg";
import logo512 from "../assets/images/carrier-logos-new/512.jpg";
import logo513 from "../assets/images/carrier-logos-new/513.jpg";
import logo514 from "../assets/images/carrier-logos-new/514.jpg";
import logo516 from "../assets/images/carrier-logos-new/516.jpg";
import logo52 from "../assets/images/carrier-logos-new/52.jpg";
import logo532 from "../assets/images/carrier-logos-new/532.jpg";
import logo542 from "../assets/images/carrier-logos-new/542.jpg";
import logo565 from "../assets/images/carrier-logos-new/565.jpg";
import logo574 from "../assets/images/carrier-logos-new/574.jpg";
import logo581 from "../assets/images/carrier-logos-new/581.jpg";
import logo591 from "../assets/images/carrier-logos-new/591.jpg";
import logo594 from "../assets/images/carrier-logos-new/594.jpg";
import logo603 from "../assets/images/carrier-logos-new/603.jpg";
import logo605 from "../assets/images/carrier-logos-new/605.jpg";
import logo609 from "../assets/images/carrier-logos-new/609.jpg";
import logo610 from "../assets/images/carrier-logos-new/610.jpg";
import logo624 from "../assets/images/carrier-logos-new/624.jpg";
import logo629 from "../assets/images/carrier-logos-new/629.jpg";
import logo650 from "../assets/images/carrier-logos-new/650.jpg";
import logo657 from "../assets/images/carrier-logos-new/657.jpg";
import logo67 from "../assets/images/carrier-logos-new/67.jpg";
import logo679 from "../assets/images/carrier-logos-new/679.jpg";
import logo684 from "../assets/images/carrier-logos-new/684.jpg";
import logo694 from "../assets/images/carrier-logos-new/694.jpg";
import logo703 from "../assets/images/carrier-logos-new/703.jpg";
import logo900 from "../assets/images/carrier-logos-new/900.jpg";
import logo966 from "../assets/images/carrier-logos-new/966.jpg";
import logo97 from "../assets/images/carrier-logos-new/97.jpg";
import logo977 from "../assets/images/carrier-logos-new/977.jpg";
import logo980 from "../assets/images/carrier-logos-new/980.jpg";
import logo982 from "../assets/images/carrier-logos-new/982.jpg";
import logo983 from "../assets/images/carrier-logos-new/983.jpg";
import logo984 from "../assets/images/carrier-logos-new/984.jpg";
import logo985 from "../assets/images/carrier-logos-new/985.jpg";
import logo986 from "../assets/images/carrier-logos-new/986.jpg";
import logo988 from "../assets/images/carrier-logos-new/988.jpg";
import logo990 from "../assets/images/carrier-logos-new/990.jpg";
import logo991 from "../assets/images/carrier-logos-new/991.jpg";
import logo992 from "../assets/images/carrier-logos-new/992.jpg";
import logo993 from "../assets/images/carrier-logos-new/993.jpg";
import logo994 from "../assets/images/carrier-logos-new/994.jpg";
import logo995 from "../assets/images/carrier-logos-new/995.jpg";
import logo998 from "../assets/images/carrier-logos-new/998.jpg";
import logo999 from "../assets/images/carrier-logos-new/999.jpg";
import logo9999 from "../assets/images/carrier-logos-new/9999.jpg";

const CARRIER_LOGOS_NEW = new Map<number, string>([
  [0, logo0],
  [97, logo97],
  [102, logo102],
  [113, logo113],
  [13, logo13],
  [131, logo131],
  [148, logo148],
  [160, logo160],
  [168, logo168],
  [17, logo17],
  [18, logo18],
  [199, logo199],
  [201, logo201],
  [203, logo203],
  [228, logo228],
  [25, logo25],
  [303, logo303],
  [304, logo304],
  [305, logo305],
  [331, logo331],
  [334, logo334],
  [343, logo343],
  [344, logo344],
  [35, logo35],
  [361, logo361],
  [363, logo363],
  [366, logo366],
  [371, logo371],
  [373, logo373],
  [377, logo377],
  [379, logo379],
  [384, logo384],
  [388, logo388],
  [39, logo39],
  [391, logo391],
  [404, logo404],
  [406, logo406],
  [416, logo416],
  [418, logo418],
  [421, logo421],
  [422, logo422],
  [425, logo425],
  [430, logo430],
  [436, logo436],
  [438, logo438],
  [445, logo445],
  [447, logo447],
  [467, logo467],
  [470, logo470],
  [473, logo473],
  [479, logo479],
  [489, logo489],
  [494, logo494],
  [498, logo498],
  [501, logo501],
  [502, logo502],
  [503, logo503],
  [504, logo504],
  [505, logo505],
  [506, logo506],
  [507, logo507],
  [509, logo509],
  [510, logo510],
  [512, logo512],
  [513, logo513],
  [514, logo514],
  [516, logo516],
  [52, logo52],
  [532, logo532],
  [542, logo542],
  [565, logo565],
  [574, logo574],
  [581, logo581],
  [591, logo591],
  [594, logo594],
  [603, logo603],
  [605, logo605],
  [609, logo609],
  [610, logo610],
  [624, logo624],
  [629, logo629],
  [650, logo650],
  [657, logo657],
  [67, logo67],
  [679, logo679],
  [684, logo684],
  [694, logo694],
  [703, logo703],
  [900, logo900],
  [966, logo966],
  [977, logo977],
  [980, logo980],
  [982, logo982],
  [983, logo983],
  [984, logo984],
  [985, logo985],
  [986, logo986],
  [988, logo988],
  [990, logo990],
  [991, logo991],
  [992, logo992],
  [993, logo993],
  [994, logo994],
  [995, logo995],
  [998, logo998],
  [999, logo999],
  [9999, logo9999],
]);

export default CARRIER_LOGOS_NEW;
