import "@fontsource-variable/rethink-sans";

import Paths from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AviatorErrorBoundary } from "./pages/Error";
import { useFeatureFlag } from "./services/featureFlag";
import { useEffect } from "react";

function App() {
  const redirectToNewAviator = useFeatureFlag().getFlag(
    "redirect-to-new-aviator"
  );

  useEffect(() => {
    if (redirectToNewAviator) {
      window.location.href =
        process.env.REACT_APP_REDIRECT_URL ||
        "https://dev.agent-quote.goosehead.com";
    }
  }, [redirectToNewAviator]);

  return (
    <Router>
      <AviatorErrorBoundary>
        <Paths />
      </AviatorErrorBoundary>
    </Router>
  );
}

export default App;
